import { FC, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import RefreshTwoToneIcon from '@mui/icons-material/RefreshTwoTone';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import { useAuthStore } from 'src/store/authStore';

const GridWrapper = styled(Grid)(
  ({ theme }) => `
    background: ${theme.colors.gradients.black1};
`
);

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
`
);

const TypographySecondary = styled(Typography)(
  ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[70]};
`
);

const SubscriptionEnded: FC = () => {
  const { t }: { t: any } = useTranslation();
 const logout = useAuthStore((state => state.logout))
  return (
    <>
      <Helmet>
        <title>Subscription Ended</title>
      </Helmet>
      <MainContent>
        <Grid
          container
          sx={{
            height: '100%'
          }}
          alignItems="stretch"
          spacing={0}
        >
          <GridWrapper
            sx={{
              display: { xs: 'flex', md: 'flex' }
            }}
            xs={12}
            alignItems="center"
            display="flex"
            justifyContent="center"
            item
          >
            <Container maxWidth="sm">
              <Box
                textAlign="center"
                sx={{
                  my: 4
                }}
              >
                <TypographyPrimary
                  variant="h1"
                  sx={{
                    my: 2
                  }}
                >
                  {t('Your subscription has ended.')}
                </TypographyPrimary>
                <TypographySecondary
                  variant="h4"
                  fontWeight="normal"
                  sx={{
                    mb: 4
                  }}
                >
                  {t(
                    'If you enjoyed Deriskly, take one minute to upgrade. Our subscription plans start from £99 a month. Upgrade now to keep using Deriskly.'
                  )}
                </TypographySecondary>
                <Button
                  href="/management/invoices"
                  size="large"
                  variant="contained"
                >
                  {t('Upgrade')}
                </Button>
                <Button
                sx={{
                  mx:2
                               }}
                  onClick={logout}
                  size="large"
                  variant="outlined"
                >
                  {t('Logout')}
                </Button>
              </Box>
            </Container>
          </GridWrapper>
        </Grid>
      </MainContent>
    </>
  );
};

export default SubscriptionEnded;
