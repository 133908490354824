import { AxiosResponse } from 'axios';
import { getDate } from 'date-fns';
import { GenerateReplyRequest } from 'src/models/request/generateReplyRequest';
import { PagingParams } from 'src/models/request/pagination';
import { SendReplyRequest } from 'src/models/request/sendReplyRequest';
import { BoardsGetUrlParsingDataResponse } from 'src/models/response/BoardsGetDataResponse';
import { BoardsService } from 'src/services/boardsService';
import { ReviewService } from 'src/services/reviewService';
import create from 'zustand';

import { devtools, persist } from 'zustand/middleware';

export enum Status {
  New,
  InProgress,
  Resolved
}
export enum Trend {
  Unchanged,
  Improving,
  Escalating
}
export interface SelectInput {
  title: string;
}
export interface Draft {
  id: string;
  draft: string;
}
const trends = [
  { title: 'Unchanged' },
  { title: 'Improving' },
  { title: 'Escalating' }
];

const status = [
  { title: 'New' },
  { title: 'In Progress' },
  { title: 'Resolved' }
];
export interface ReviewStore {
  singleReview: BoardsGetUrlParsingDataResponse;
  reviews: BoardsGetUrlParsingDataResponse[];

  pageNumber: number;
  timezone: string;
  pageSize: number;
  total: number;
  totalUnanswered: number;
  ebayTotalUnanswered: number;
  amazonTotalUnanswered: number;
  trustpilotTotalUnanswered: number;
  googleReviewTotalUnanswered: number;
  ids: string;
  autoRefresh: boolean;

  //filters

  ratings: SelectInput[];
  status: SelectInput[];
  startDate: string;
  endDate: string;
  searchString: string;
  sortBy: string;
  drafts: Draft[];
  generatingReply: boolean;
  loadingReviews: boolean;
  showDraft: boolean;

  //actions
  setReviews: () => void;
  setStartDate: (startDate: string) => void;
  setEndDate: (startDate: string) => void;
  setSingleReview: (id: string) => void;
  updateStatus: (id: string, status: string) => void;
  updateDraft: (id: string, draft: string) => void;
  setFitlerStatus: (statuses: SelectInput[]) => void;
  setFitlerRatings: (ratings: SelectInput[]) => void;
  setFitlerSearchString: (statuses: string) => void;
  setAutoRefresh: (autorefresh: boolean) => void;
  sendReply: (reply: SendReplyRequest) => void;
  generateReply: (reviewId: string, message: string) => void;
  setSort: (value: string) => void;
  MarkAsOpened: (value: string[]) => void;
  MarkAsAnswered: (value: string[]) => void;
  archiveReviews: (value: string[]) => void;
  updateLoadingReviews: (value: boolean) => void;
  updatepageNumber: (value: number) => void;
  assign: (id: string, toUserId: string) => void;
  useTemplate: (mailId: string, body: string) => void;
  updateShowDraft: (id: string, value: boolean) => void;
  updateGeneratingReply: (id: string, value: boolean) => void;
}

export const useTrustpilotReviewStore = create<ReviewStore>()(
  devtools(
    persist((set, get) => ({
      singleReview: null,
      reviews: null,
      status: [],
      startDate: (() => {
        var d = new Date();
        d.setMonth(d.getMonth() - 1);
        return d;
      })().toISOString(),
      endDate: new Date().toDateString(),
      pageNumber: 1,
      timezone: '',
      pageSize: 10,
      total: 0,
      ids: '00000000-0000-0000-0000-000000000000',
      totalUnanswered: 0,
      ebayTotalUnanswered: 0,
      googleReviewTotalUnanswered: 0,
      amazonTotalUnanswered: 0,
      trustpilotTotalUnanswered: 0,
      //filter
      ratings: [],
      searchString: '',
      sortBy: 'Sent Date Time desc',
      autoRefresh: true,

      sideBarOpen: false,
      drafts: [],
      generatingReply: false,
      loadingReviews: false,
      showDraft: false,
      tags: [
        {
          id: 'reviews',
          type: 'heading',
          name: 'Reviews'
        },
        {
          id: 'inbox',
          type: 'category_tag',
          name: 'Inbox',
          newReviews: 7
        },
        {
          id: 'sent',
          type: 'category_tag',
          name: 'Sent',
          newReviews: 0
        },
        {
          id: 'statuses',
          type: 'heading',
          name: 'Statuses'
        },
        {
          id: 'new',
          type: 'label_tag',
          name: 'New',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'inprogress',
          type: 'label_tag',
          name: 'In Progress',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'resolved',
          type: 'label_tag',
          name: 'Resolved',
          newReviews: 1,
          color: 'green'
        },
        {
          id: 'trend',
          type: 'heading',
          name: 'Trends'
        },
        {
          id: 'escalating',
          type: 'label_tag',
          name: 'Escalating',
          newReviews: 4,
          color: '#FF1943'
        },
        {
          id: 'unchanged',
          type: 'label_tag',
          name: 'Unchaged',
          newReviews: 3,
          color: '#FFA319'
        },
        {
          id: 'improving',
          type: 'label_tag',
          name: 'Improving',
          newReviews: 3,
          color: '#57CA22'
        }
      ],
      setReviews: async () => {
        const filter: PagingParams = {
          endDate: get().endDate,
          pageNumber: get().pageNumber,
          pageSize: get().pageSize,
          ids: '00000000-0000-0000-0000-000000000000',
          startDate: get().startDate,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          trends: get().ratings,
          status: get().status,
          searchString: get().searchString,
          sortBy: get().sortBy,
          sourceType: 'trustpilot.com'
        };
        const result = await BoardsService.getUrlParsingData(filter);
        set({ total: result.data.total });
        set({ trustpilotTotalUnanswered: result.data.totalUnAnswered });
        set({ reviews: result.data.reviews });
        set({ loadingReviews: false });
      },
      setStartDate: (value) => {
        set({ startDate: value });
      },
      setEndDate: (value) => {
        set({ endDate: value });
      },
      setSingleReview: (id) => {
        if (get().reviews !== null && id !== undefined) {
          const singlreview = get().reviews.filter((x) => x.id == id);
          set({ singleReview: singlreview[0] });
        }
      },
      updateStatus: async (id, status) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  status: status
                }
              : e
          );
          set({ reviews: currentReviews });
          await BoardsService.UpdateStatus(status, id);
        }
      },
      setSort: (value) => {
        set({ sortBy: value });
      },
      updateDraft: (id, draft) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  draft: draft
                }
              : e
          );
          var single = get().singleReview;
          single.draft = draft;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },

      updateShowDraft: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  showDraft: value
                }
              : e
          );
          var single = get().singleReview;
          single.showDraft = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      updateGeneratingReply: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  generatingReply: value
                }
              : e
          );
          var single = get().singleReview;
          single.generatingReply = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      setFitlerStatus: async (statuses) => {
        set({ status: statuses });
        await get().setReviews();
      },
      setFitlerRatings: (value) => {
        set({ ratings: value });
      },
      setFitlerSearchString: (value) => {
        set({ searchString: value });
      },
      setAutoRefresh: (autorefresh) => {
        set({ autoRefresh: autorefresh });
      },
      sendReply: (reply: SendReplyRequest) => {
        BoardsService.sendReply(reply);
      },
      generateReply: async (reviewId: string, generateReply: string) => {
        if (get().reviews !== null) {
          let currentReviews = get().reviews.map((e) =>
            e.id === reviewId
              ? {
                  ...e,
                  generateReplay: generateReply
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = generateReply;
          set({ singleReview: single });
          get().updateShowDraft(reviewId, true);
        }

        get().updateGeneratingReply(reviewId, false);
      },
      MarkAsOpened: async (value: string[]) => {
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    opened: true
                  }
                : e
            );
            set({ reviews: currentReviews });
          }
        });

        ReviewService.MarkAsOpened(value);
      },
      MarkAsAnswered: async (value: string[]) => {
        let single = get().singleReview;
        single.answered = !single.answered;
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    answered: !e.answered
                  }
                : e
            );
            set({ reviews: currentReviews });
            set({ singleReview: single });
          }
        });
      },
      archiveReviews: async (value: string[]) => {
        value.forEach((reviewId) => {
          var filteredReviews = get().reviews.filter(
            (x) => x.objectId !== reviewId
          );
          set({ reviews: filteredReviews });
          filteredReviews = get().reviews.filter((x) => x.id !== reviewId);
          set({ reviews: filteredReviews });
        });

        await ReviewService.archive(value);
      },
      updateLoadingReviews: (value: boolean) => {
        set({ loadingReviews: value });
      },
      updatepageNumber: (value: number) => {
        set({ pageNumber: value });
      },
      assign: async (id: string, toUserId: string) => {
        var result = await BoardsService.assign(id, toUserId, 4);
        if (result.status == 200) {
          get().setReviews();
        }
      },

      useTemplate: async (mailId: string, body: string) => {
        if (get().reviews !== null) {
          let currentReviews = get().reviews.map((e) =>
            e.id === mailId
              ? {
                  ...e,
                  generateReplay: body
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = body;
          set({ singleReview: single });
          get().updateShowDraft(mailId, true);
        }
      },
      setShowDraft: (value: boolean) => {
        set({ showDraft: value });
      }
    }))
  )
);

export const useEbayReviewStore = create<ReviewStore>()(
  devtools(
    persist((set, get) => ({
      singleReview: null,
      reviews: null,
      status: [],
      startDate: (() => {
        var d = new Date();
        d.setMonth(d.getMonth() - 1);
        return d;
      })().toISOString(),
      endDate: new Date().toDateString(),
      pageNumber: 1,
      timezone: '',
      pageSize: 10,
      total: 0,
      ids: '00000000-0000-0000-0000-000000000000',
      totalUnanswered: 0,
      ebayTotalUnanswered: 0,
      googleReviewTotalUnanswered: 0,
      amazonTotalUnanswered: 0,
      trustpilotTotalUnanswered: 0,
      //filter
      ratings: [],
      searchString: '',
      sortBy: 'Sent Date Time desc',
      autoRefresh: true,

      sideBarOpen: false,
      drafts: [],
      generatingReply: false,
      loadingReviews: false,
      showDraft: false,
      tags: [
        {
          id: 'reviews',
          type: 'heading',
          name: 'Reviews'
        },
        {
          id: 'inbox',
          type: 'category_tag',
          name: 'Inbox',
          newReviews: 7
        },
        {
          id: 'sent',
          type: 'category_tag',
          name: 'Sent',
          newReviews: 0
        },
        {
          id: 'statuses',
          type: 'heading',
          name: 'Statuses'
        },
        {
          id: 'new',
          type: 'label_tag',
          name: 'New',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'inprogress',
          type: 'label_tag',
          name: 'In Progress',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'resolved',
          type: 'label_tag',
          name: 'Resolved',
          newReviews: 1,
          color: 'green'
        },
        {
          id: 'trend',
          type: 'heading',
          name: 'Trends'
        },
        {
          id: 'escalating',
          type: 'label_tag',
          name: 'Escalating',
          newReviews: 4,
          color: '#FF1943'
        },
        {
          id: 'unchanged',
          type: 'label_tag',
          name: 'Unchaged',
          newReviews: 3,
          color: '#FFA319'
        },
        {
          id: 'improving',
          type: 'label_tag',
          name: 'Improving',
          newReviews: 3,
          color: '#57CA22'
        }
      ],
      setReviews: async () => {
        const filter: PagingParams = {
          endDate: get().endDate,
          pageNumber: get().pageNumber,
          pageSize: get().pageSize,
          ids: '00000000-0000-0000-0000-000000000000',
          startDate: get().startDate,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          trends: get().ratings,
          status: get().status,
          searchString: get().searchString,
          sortBy: get().sortBy,
          sourceType: 'ebay'
        };
        const result = await BoardsService.getUrlParsingData(filter);
        set({ total: result.data.total });
        set({ ebayTotalUnanswered: result.data.totalUnAnswered });
        set({ reviews: result.data.reviews });
        set({ loadingReviews: false });
      },
      setStartDate: (value) => {
        set({ startDate: value });
      },
      setEndDate: (value) => {
        set({ endDate: value });
      },
      setSingleReview: (id) => {
        if (get().reviews !== null && id !== undefined) {
          const singlreview = get().reviews.filter((x) => x.id == id);
          set({ singleReview: singlreview[0] });
        }
      },
      updateStatus: async (id, status) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  status: status
                }
              : e
          );
          set({ reviews: currentReviews });
          await BoardsService.UpdateStatus(status, id);
        }
      },
      setSort: (value) => {
        set({ sortBy: value });
      },
      updateDraft: (id, draft) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  draft: draft
                }
              : e
          );
          var single = get().singleReview;
          single.draft = draft;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },

      updateShowDraft: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  showDraft: value
                }
              : e
          );
          var single = get().singleReview;
          single.showDraft = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      updateGeneratingReply: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  generatingReply: value
                }
              : e
          );
          var single = get().singleReview;
          single.generatingReply = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      setFitlerStatus: (statuses) => {
        set({ status: statuses });
      },
      setFitlerRatings: (value) => {
        set({ ratings: value });
      },
      setFitlerSearchString: (value) => {
        set({ searchString: value });
      },
      setAutoRefresh: (autorefresh) => {
        set({ autoRefresh: autorefresh });
      },
      sendReply: (reply: SendReplyRequest) => {
        ReviewService.SendEbayReply(reply);
      },
      generateReply: async (reviewId: string, message: string) => {
        get().updateGeneratingReply(reviewId, true);
        const request: GenerateReplyRequest = {
          MailId: reviewId,
          Message: message
        };
        const generateReply = await BoardsService.generateReviewReply(request);

        if (get().reviews !== null && generateReply.status === 200) {
          let currentReviews = get().reviews.map((e) =>
            e.id === reviewId
              ? {
                  ...e,
                  generateReplay: generateReply.data
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = generateReply.data;
          set({ singleReview: single });
          get().updateShowDraft(reviewId, true);
        }

        get().updateGeneratingReply(reviewId, false);
      },
      MarkAsOpened: async (value: string[]) => {
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    opened: true
                  }
                : e
            );
            set({ reviews: currentReviews });
          }
        });

        ReviewService.MarkAsOpened(value);
      },
      MarkAsAnswered: async (value: string[]) => {
        let single = get().singleReview;
        single.answered = !single.answered;
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    answered: !e.answered
                  }
                : e
            );
            set({ reviews: currentReviews });
            set({ singleReview: single });
          }
        });
      },
      archiveReviews: async (value: string[]) => {
        value.forEach((reviewId) => {
          var filteredReviews = get().reviews.filter(
            (x) => x.objectId !== reviewId
          );
          set({ reviews: filteredReviews });
          filteredReviews = get().reviews.filter((x) => x.id !== reviewId);
          set({ reviews: filteredReviews });
        });

        await ReviewService.archive(value);
      },
      updateLoadingReviews: (value: boolean) => {
        set({ loadingReviews: value });
      },
      updatepageNumber: (value: number) => {
        set({ pageNumber: value });
      },
      assign: async (id: string, toUserId: string) => {
        var result = await BoardsService.assign(id, toUserId, 4);
        if (result.status == 200) {
          get().setReviews();
        }
      },

      useTemplate: async (mailId: string, body: string) => {
        if (get().reviews !== null) {
          let currentReviews = get().reviews.map((e) =>
            e.id === mailId
              ? {
                  ...e,
                  generateReplay: body
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = body;
          set({ singleReview: single });
          get().updateShowDraft(mailId, true);
        }
      },
      setShowDraft: (value: boolean) => {
        set({ showDraft: value });
      }
    }))
  )
);

export const useGoogleReviewStore = create<ReviewStore>()(
  devtools(
    persist((set, get) => ({
      singleReview: null,
      reviews: null,
      status: [],
      startDate: (() => {
        var d = new Date();
        d.setMonth(d.getMonth() - 1);
        return d;
      })().toISOString(),
      endDate: new Date().toDateString(),
      pageNumber: 1,
      timezone: '',
      pageSize: 10,
      total: 0,
      ids: '00000000-0000-0000-0000-000000000000',
      totalUnanswered: 0,
      ebayTotalUnanswered: 0,
      googleReviewTotalUnanswered: 0,
      amazonTotalUnanswered: 0,
      trustpilotTotalUnanswered: 0,
      //filter
      ratings: [],
      searchString: '',
      sortBy: 'Sent Date Time desc',
      autoRefresh: true,

      sideBarOpen: false,
      drafts: [],
      generatingReply: false,
      loadingReviews: false,
      showDraft: false,
      tags: [
        {
          id: 'reviews',
          type: 'heading',
          name: 'Reviews'
        },
        {
          id: 'inbox',
          type: 'category_tag',
          name: 'Inbox',
          newReviews: 7
        },
        {
          id: 'sent',
          type: 'category_tag',
          name: 'Sent',
          newReviews: 0
        },
        {
          id: 'statuses',
          type: 'heading',
          name: 'Statuses'
        },
        {
          id: 'new',
          type: 'label_tag',
          name: 'New',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'inprogress',
          type: 'label_tag',
          name: 'In Progress',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'resolved',
          type: 'label_tag',
          name: 'Resolved',
          newReviews: 1,
          color: 'green'
        },
        {
          id: 'trend',
          type: 'heading',
          name: 'Trends'
        },
        {
          id: 'escalating',
          type: 'label_tag',
          name: 'Escalating',
          newReviews: 4,
          color: '#FF1943'
        },
        {
          id: 'unchanged',
          type: 'label_tag',
          name: 'Unchaged',
          newReviews: 3,
          color: '#FFA319'
        },
        {
          id: 'improving',
          type: 'label_tag',
          name: 'Improving',
          newReviews: 3,
          color: '#57CA22'
        }
      ],
      setReviews: async () => {
        const filter: PagingParams = {
          endDate: get().endDate,
          pageNumber: get().pageNumber,
          pageSize: get().pageSize,
          ids: '00000000-0000-0000-0000-000000000000',
          startDate: get().startDate,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          trends: get().ratings,
          status: get().status,
          searchString: get().searchString,
          sortBy: get().sortBy,
          sourceType: 'googleReview'
        };
        const result = await BoardsService.getUrlParsingData(filter);
        set({ total: result.data.total });
        set({ googleReviewTotalUnanswered: result.data.totalUnAnswered });
        set({ reviews: result.data.reviews });
        set({ loadingReviews: false });
      },
      setStartDate: (value) => {
        set({ startDate: value });
      },
      setEndDate: (value) => {
        set({ endDate: value });
      },
      setSingleReview: (id) => {
        if (get().reviews !== null && id !== undefined) {
          const singlreview = get().reviews.filter((x) => x.id == id);
          set({ singleReview: singlreview[0] });
        }
      },
      updateStatus: async (id, status) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  status: status
                }
              : e
          );
          set({ reviews: currentReviews });
          await BoardsService.UpdateStatus(status, id);
        }
      },
      setSort: (value) => {
        set({ sortBy: value });
      },
      updateDraft: (id, draft) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  draft: draft
                }
              : e
          );
          var single = get().singleReview;
          single.draft = draft;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },

      updateShowDraft: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  showDraft: value
                }
              : e
          );
          var single = get().singleReview;
          single.showDraft = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      updateGeneratingReply: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  generatingReply: value
                }
              : e
          );
          var single = get().singleReview;
          single.generatingReply = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      setFitlerStatus: (statuses) => {
        set({ status: statuses });
      },
      setFitlerRatings: (value) => {
        set({ ratings: value });
      },
      setFitlerSearchString: (value) => {
        set({ searchString: value });
      },
      setAutoRefresh: (autorefresh) => {
        set({ autoRefresh: autorefresh });
      },
      sendReply: (reply: SendReplyRequest) => {
        ReviewService.SendGoogleReviewReply(reply);
      },
      generateReply: async (reviewId: string, message: string) => {
        get().updateGeneratingReply(reviewId, true);
        const request: GenerateReplyRequest = {
          MailId: reviewId,
          Message: message
        };
        const generateReply = await BoardsService.generateReviewReply(request);

        if (get().reviews !== null && generateReply.status === 200) {
          let currentReviews = get().reviews.map((e) =>
            e.id === reviewId
              ? {
                  ...e,
                  generateReplay: generateReply.data
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = generateReply.data;
          set({ singleReview: single });
          get().updateShowDraft(reviewId, true);
        }

        get().updateGeneratingReply(reviewId, false);
      },
      MarkAsOpened: async (value: string[]) => {
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    opened: true
                  }
                : e
            );
            set({ reviews: currentReviews });
          }
        });

        ReviewService.MarkAsOpened(value);
      },
      MarkAsAnswered: async (value: string[]) => {
        let single = get().singleReview;
        single.answered = !single.answered;
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    answered: !e.answered
                  }
                : e
            );
            set({ reviews: currentReviews });
            set({ singleReview: single });
          }
        });
      },
      archiveReviews: async (value: string[]) => {
        value.forEach((reviewId) => {
          var filteredReviews = get().reviews.filter(
            (x) => x.objectId !== reviewId
          );
          set({ reviews: filteredReviews });
          filteredReviews = get().reviews.filter((x) => x.id !== reviewId);
          set({ reviews: filteredReviews });
        });

        await ReviewService.archive(value);
      },
      updateLoadingReviews: (value: boolean) => {
        set({ loadingReviews: value });
      },
      updatepageNumber: (value: number) => {
        set({ pageNumber: value });
      },
      assign: async (id: string, toUserId: string) => {
        var result = await BoardsService.assign(id, toUserId, 4);
        if (result.status == 200) {
          get().setReviews();
        }
      },

      useTemplate: async (mailId: string, body: string) => {
        if (get().reviews !== null) {
          let currentReviews = get().reviews.map((e) =>
            e.id === mailId
              ? {
                  ...e,
                  generateReplay: body
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = body;
          set({ singleReview: single });
          get().updateShowDraft(mailId, true);
        }
      },
      setShowDraft: (value: boolean) => {
        set({ showDraft: value });
      }
    }))
  )
);

export const useAmazonReviewStore = create<ReviewStore>()(
  devtools(
    persist((set, get) => ({
      singleReview: null,
      reviews: null,
      status: [],
      startDate: (() => {
        var d = new Date();
        d.setMonth(d.getMonth() - 1);
        return d;
      })().toISOString(),
      endDate: new Date().toDateString(),
      pageNumber: 1,
      timezone: '',
      pageSize: 10,
      total: 0,
      ids: '00000000-0000-0000-0000-000000000000',
      totalUnanswered: 0,
      ebayTotalUnanswered: 0,
      googleReviewTotalUnanswered: 0,
      amazonTotalUnanswered: 0,
      trustpilotTotalUnanswered: 0,
      //filter
      ratings: [],
      searchString: '',
      sortBy: 'Sent Date Time desc',
      autoRefresh: true,

      sideBarOpen: false,
      drafts: [],
      generatingReply: false,
      loadingReviews: false,
      showDraft: false,
      tags: [
        {
          id: 'reviews',
          type: 'heading',
          name: 'Reviews'
        },
        {
          id: 'inbox',
          type: 'category_tag',
          name: 'Inbox',
          newReviews: 7
        },
        {
          id: 'sent',
          type: 'category_tag',
          name: 'Sent',
          newReviews: 0
        },
        {
          id: 'statuses',
          type: 'heading',
          name: 'Statuses'
        },
        {
          id: 'new',
          type: 'label_tag',
          name: 'New',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'inprogress',
          type: 'label_tag',
          name: 'In Progress',
          newReviews: 0,
          color: 'green'
        },
        {
          id: 'resolved',
          type: 'label_tag',
          name: 'Resolved',
          newReviews: 1,
          color: 'green'
        },
        {
          id: 'trend',
          type: 'heading',
          name: 'Trends'
        },
        {
          id: 'escalating',
          type: 'label_tag',
          name: 'Escalating',
          newReviews: 4,
          color: '#FF1943'
        },
        {
          id: 'unchanged',
          type: 'label_tag',
          name: 'Unchaged',
          newReviews: 3,
          color: '#FFA319'
        },
        {
          id: 'improving',
          type: 'label_tag',
          name: 'Improving',
          newReviews: 3,
          color: '#57CA22'
        }
      ],
      setReviews: async () => {
        const filter: PagingParams = {
          endDate: get().endDate,
          pageNumber: get().pageNumber,
          pageSize: get().pageSize,
          ids: '00000000-0000-0000-0000-000000000000',
          startDate: get().startDate,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          trends: get().ratings,
          status: get().status,
          searchString: get().searchString,
          sortBy: get().sortBy,
          sourceType: 'amazon.com'
        };
        const result = await BoardsService.getUrlParsingData(filter);
        set({ total: result.data.total });
        set({ amazonTotalUnanswered: result.data.totalUnAnswered });
        set({ reviews: result.data.reviews });
        set({ loadingReviews: false });
      },
      setStartDate: (value) => {
        set({ startDate: value });
      },
      setEndDate: (value) => {
        set({ endDate: value });
      },
      setSingleReview: (id) => {
        if (get().reviews !== null && id !== undefined) {
          const singlreview = get().reviews.filter((x) => x.id == id);
          set({ singleReview: singlreview[0] });
        }
      },
      updateStatus: async (id, status) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  status: status
                }
              : e
          );
          set({ reviews: currentReviews });
          await BoardsService.UpdateStatus(status, id);
        }
      },
      setSort: (value) => {
        set({ sortBy: value });
      },
      updateDraft: (id, draft) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  draft: draft
                }
              : e
          );
          var single = get().singleReview;
          single.draft = draft;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },

      updateShowDraft: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  showDraft: value
                }
              : e
          );
          var single = get().singleReview;
          single.showDraft = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      updateGeneratingReply: (id, value) => {
        if (get().reviews !== null && id !== undefined) {
          let currentReviews = get().reviews.map((e) =>
            e.id === id
              ? {
                  ...e,
                  generatingReply: value
                }
              : e
          );
          var single = get().singleReview;
          single.generatingReply = value;
          set({ reviews: currentReviews });
          set({ singleReview: single });
        }
      },
      setFitlerStatus: (statuses) => {
        set({ status: statuses });
      },
      setFitlerRatings: (value) => {
        set({ ratings: value });
      },
      setFitlerSearchString: (value) => {
        set({ searchString: value });
      },
      setAutoRefresh: (autorefresh) => {
        set({ autoRefresh: autorefresh });
      },
      sendReply: (reply: SendReplyRequest) => {
        BoardsService.sendReply(reply);
      },
      generateReply: async (mailId: string, message: string) => {
        get().updateGeneratingReply(mailId, true);
        const request: GenerateReplyRequest = {
          MailId: mailId,
          Message: message
        };
        const generateReply = await BoardsService.generateReviewReply(request);

        if (get().reviews !== null && generateReply.status === 200) {
          let currentReviews = get().reviews.map((e) =>
            e.id === mailId
              ? {
                  ...e,
                  generateReplay: generateReply.data
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = generateReply.data;
          set({ singleReview: single });
          get().updateShowDraft(mailId, true);
        }

        get().updateGeneratingReply(mailId, false);
      },
      MarkAsOpened: async (value: string[]) => {
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    opened: true
                  }
                : e
            );
            set({ reviews: currentReviews });
          }
        });

        ReviewService.MarkAsOpened(value);
      },
      MarkAsAnswered: async (value: string[]) => {
        let single = get().singleReview;
        single.answered = !single.answered;
        value.forEach((reviewId) => {
          if (get().reviews !== null && reviewId !== undefined) {
            let currentReviews = get().reviews.map((e) =>
              e.objectId === reviewId
                ? {
                    ...e,
                    answered: !e.answered
                  }
                : e
            );
            set({ reviews: currentReviews });
            set({ singleReview: single });
          }
        });
      },
      archiveReviews: async (value: string[]) => {
        value.forEach((reviewId) => {
          var filteredReviews = get().reviews.filter(
            (x) => x.objectId !== reviewId
          );
          set({ reviews: filteredReviews });
          filteredReviews = get().reviews.filter((x) => x.id !== reviewId);
          set({ reviews: filteredReviews });
        });

        await ReviewService.archive(value);
      },
      updateLoadingReviews: (value: boolean) => {
        set({ loadingReviews: value });
      },
      updatepageNumber: (value: number) => {
        set({ pageNumber: value });
        get().setReviews();
      },
      assign: async (id: string, toUserId: string) => {
        var result = await BoardsService.assign(id, toUserId, 5);
        if (result.status == 200) {
          get().setReviews();
        }
      },

      useTemplate: async (mailId: string, body: string) => {
        if (get().reviews !== null) {
          let currentReviews = get().reviews.map((e) =>
            e.id === mailId
              ? {
                  ...e,
                  generateReplay: body
                }
              : e
          );
          var single = get().singleReview;
          set({ reviews: currentReviews });
          single.generateReplay = body;
          set({ singleReview: single });
          get().updateShowDraft(mailId, true);
        }
      },
      setShowDraft: (value: boolean) => {
        set({ showDraft: value });
      }
    }))
  )
);
