import { AxiosResponse } from 'axios';
import { AccountResponse } from 'src/models/response/AccountResponse';
import { AuthResponse } from 'src/models/response/AuthResponse';
import { User, UserResponse } from 'src/models/user';
import { ApiService, StorageKeys } from './apiService';
import { GoogleReviewSigninRequest } from 'src/content/applications/GoogleReviews/Config';

export class AuthService {
  static async login(
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post<AuthResponse>('/Account/LocalSignIn', {
      email,
      password
    });
  }

  static async register(
    username: string,
    email: string,
    password: string,
    selectedFeatures: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post<AuthResponse>('/Account/LocalSignUp', {
      name: username,
      email,
      password,
      selectedFeatures
    });
  }

  static async resetPassword(
    email: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post<AuthResponse>('/Account/ForgotPassword', {
      email
    });
  }

  static async confirmResetPassword(
    userId: string,
    token: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post<AuthResponse>(
      '/Account/ForgotPasswordConfirmation',
      {
        userId,
        confirmationToken: token,
        password
      }
    );
  }

  static async confirmRegistration(
    userId: string,
    token: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post<AuthResponse>('/Account/ConfirmRegistration', {
      userId,
      confirmationToken: token
    });
  }

  static resetAuth() {
    localStorage.removeItem(StorageKeys.Token);
    localStorage.removeItem(StorageKeys.User);
  }

  static async logout(refreshToken: string): Promise<void> {
    return ApiService.api.post('/Account/SignOut', { refreshToken });
  }

  static async regenerateNewTokens(
    refreshToken: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post('/Account/Refresh', { refreshToken });
  }

  static async microsoftSignIn(
    code: string,
    state?: string,
    alreadyLoggedIn?: boolean
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post('/Account/MicrosoftSignIn', {
      code,
      state,
      alreadyLoggedIn
    });
  }

  static async googleSignIn(
    code: string,
    state?: string,
    alreadyLoggedIn?: boolean
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post('/Account/GoogleSignIn', {
      code,
      state,
      alreadyLoggedIn
    });
  }

  static async ebaySignInFirst(userId: string): Promise<AxiosResponse<string>> {
    return ApiService.api.get(`/Account/EbaySignIn?userId=${userId}`);
  }

  static async GetScopes(
    email: string,
    provider: string
  ): Promise<AxiosResponse<string>> {
    return ApiService.api.get(
      `/Account/GetScopes?email=${email}&provider=${provider}`
    );
  }

  static async forgotPassword(emailId: string): Promise<AxiosResponse<string>> {
    return ApiService.api.post(`/Account/ForgotPassword`, { Email: emailId });
  }

  static async forgotPasswordConfirmation(
    userId: string,
    password: string,
    token: string
  ): Promise<AxiosResponse<string>> {
    return ApiService.api.post(`/Account/ForgotPasswordConfirmation`, {
      UserId: userId,
      Password: password,
      ConfirmationToken: token
    });
  }

  static async ebaySignIn(
    code: string,
    state: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post('/Account/EbaySignIn', { code, state });
  }

  static async gorgiasSignIn(
    code: string,
    state: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post('/Account/GorgiasSignIn', { code, state });
  }

  static async revokeEbayAccess(): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/Account/RevokeEbayAccess');
  }

  static async googleReviewSignInFirst(
    request: GoogleReviewSigninRequest
  ): Promise<AxiosResponse<string>> {
    return ApiService.api.post(`/Account/GoogleReviewSignInFirst`, request);
  }

  static async googleReviewSignIn(
    code: string,
    state: string
  ): Promise<AxiosResponse<AuthResponse>> {
    return ApiService.api.post('/Account/GoogleReviewSignIn', { code, state });
  }

  static async revokeGoogleReviewAccess(): Promise<AxiosResponse<string>> {
    return ApiService.api.post('/Account/RevokeGoogleReviewAccess');
  }

  static async getAccountInfo(): Promise<AxiosResponse<AccountResponse>> {
    return ApiService.api.get('/Account/Me');
  }

  static async savePreferSourceType(type: string | number): Promise<void> {
    return ApiService.api.put(`/Account/SavePreferSourceType?type=${type}`);
  }

  static async getPreferSourceType(): Promise<AxiosResponse<string | number>> {
    return ApiService.api.get('/Account/GetPreferSourceType');
  }

  static async getUsers(): Promise<AxiosResponse<UserResponse[]>> {
    return ApiService.api.get('/Account/GetUsers');
  }
}
