import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
  Container,
  Alert,
  styled,
  Grid,
  Button,
  TextField
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Auth0Login from '../LoginAuth0';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import JWTLogin from '../LoginJWT';
import AmplifyLogin from '../LoginAmplify';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import { ReactComponent as MicrosoftBtnSVG } from '../../../../../assets/images/microsoft.svg';
import { ReactComponent as GoogleBtnSVG } from '../../../../../assets/images/google.svg';
import { ReactComponent as GorgiasBtnSVG } from '../../../../../assets/images/gorgias.svg';

import { ChangeEvent, useState } from 'react';
import { AuthService } from 'src/services/authService';
import LoopIcon from '@mui/icons-material/Loop';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
const icons = {
  Auth0: '/static/images/logo/auth0.svg',
  FirebaseAuth: '/static/images/logo/firebase.svg',
  JWT: '/static/images/logo/jwt.svg',
  Amplify: '/static/images/logo/amplify.svg'
};

const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
  @media (min-width: ${theme.breakpoints.values.md}px) {
    padding: 0 0 0 440px;
  }
  width: 100%;
  display: flex;
  align-items: center;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: ${theme.colors.alpha.white[100]};
    width: 440px;
`
);

const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);
export const MicrosoftSignInButton = styled('button')({
  cursor: 'pointer',
  fontFamily: 'Barlow',
  fontSize: '16px',
  fontWeight: 600,
  color: 'dimgray',
  border: '1px solid gray',
  borderRadius: 0,
  width: '15.5rem',
  height: '40px',
  marginTop: '1rem',
  padding: '10px',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& img': {
    marginRight: '15px'
  },
  '&:hover': {
    backgroundColor: '#f1f1f1'
  },
  '&:active': {
    backgroundColor: '#cfcfcf'
  }
});
export const GoogleSignInButton = styled('button')({
  cursor: 'pointer',
  fontFamily: 'Barlow',
  fontSize: '16px',
  fontWeight: 600,
  color: 'dimgray',
  border: '1px solid gray',
  borderRadius: 0,
  width: '15.5rem',
  height: '40px',
  marginTop: '1rem',
  padding: '10px',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& img': {
    marginRight: '15px'
  },
  '&:hover': {
    backgroundColor: '#f1f1f1'
  },
  '&:active': {
    backgroundColor: '#cfcfcf'
  }
});

export const GorgiasSignInButton = styled('button')({
  cursor: 'pointer',
  fontFamily: 'Barlow',
  fontSize: '16px',
  fontWeight: 600,
  color: 'dimgray',
  border: '1px solid gray',
  borderRadius: 0,
  width: '15.5rem',
  height: '40px',
  marginTop: '1rem',
  padding: '10px',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& img': {
    marginRight: '15px'
  },
  '&:hover': {
    backgroundColor: '#f1f1f1'
  },
  '&:active': {
    backgroundColor: '#cfcfcf'
  }
});
const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

export const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`
);

function LoginCover() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [choosenSigninMethod, setChoosenSigninMethod] = useState('none');
  const [showGorgiasInput, setShowGorgiasInput] = useState(false);
  const [showMicrosoftButton, setShowMicrosoftButton] = useState(false);
  const [showGoogleButton, setShowGoogleButton] = useState(false);
  const [showLoginWithUPButton, setShowLoginWithUPButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [gorgiasSubdomain, setGorgiasSubdomain] = useState('');
  const [gorgiasSubdomainError, setGorgiasSubdomainError] = useState('');
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();
  const [selectedFeatures, setSelectedFeatures] = useState(
    'EbayReviews,GoogleReviews,AmazonReviews,TrustPilot'
  );

  const setGorgiasSignInMethod = () => {
    setChoosenSigninMethod('Gorgias');
    setShowGorgiasInput(true);
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result = expression.test(event.target.value);
    if (!result) {
      setEmailError('email is invalid');
    } else {
      setEmailError('');
    }
  };

  const handleGorgiasAccountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGorgiasSubdomain(event.target.value);
  };

  const microsoftSignIn = () => {
    window.open(
      'https://api.deriskly.com/Account/MicrosoftSignIn?state=' +
        selectedFeatures,
      '_self'
    );
  };
  const googleSignIn = () => {
    window.open(
      'https://api.deriskly.com/Account/GoogleSignIn?state=' + selectedFeatures,
      '_self'
    );
  };

  const gorgiasSignIn = () => {
    if (gorgiasSubdomain === '') {
      setGorgiasSubdomainError('Enter Gorgias subdomain');
    } else {
      setGorgiasSubdomainError('');
      window.open(
        'https://api.deriskly.com/account/GorgiasSignin?account=' +
          gorgiasSubdomain.toLowerCase().replace('.gorgias.com', ''),
        '_self'
      );
    }
  };
  return (
    <>
      <Helmet>
        <title>Login - Cover</title>
      </Helmet>
      <Content>
        <SidebarWrapper
          sx={{
            display: { xs: 'none', md: 'flex' }
          }}
        >
          <Scrollbar>
            <SidebarContent>
              {/* <Logo /> */}
              <Box mt={6}>
                <Box
                  sx={{
                    position: 'relative',
                    width: 300,
                    height: 120,
                    mb: 7
                  }}
                >
                  <Card
                    sx={{
                      width: 300,
                      height: 120
                      // left: -20,
                      // top: -40
                    }}
                  >
                    <img
                      width={280}
                      src="/static/images/logo/Derisklylogo.png"
                    />
                  </Card>
                </Box>
                <TypographyH1
                  variant="h1"
                  sx={{
                    mb: 3
                  }}
                >
                  {t('Welcome to Deriskly')}
                </TypographyH1>

                <Typography
                  variant="subtitle1"
                  sx={{
                    my: 3
                  }}
                >
                  {t(
                    'Leveraging the power of AI developed by Oxford computers scientists, Deriskly provides a smarter way of managing, resolving and learning from your customers’ problems'
                  )}
                </Typography>
                {/* <Typography
                  variant="subtitle1"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Want to switch auth methods?')}
                </Typography>
                <Typography variant="subtitle1">
                  {t(
                    'It only takes seconds. There is a documentation section showing how to do exactly that'
                  )}
                  .{' '}
                  <Link component={RouterLink} to="/docs">
                    Read docs
                  </Link>
                </Typography> */}
              </Box>
            </SidebarContent>
          </Scrollbar>
        </SidebarWrapper>
        <MainContent>
          <Container
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
            maxWidth="sm"
          >
            <Card
              sx={{
                p: 4,
                my: 4
              }}
            >
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1
                  }}
                >
                  {t('Sign in')}
                </Typography>
              </Box>

              <Grid container justifyContent="center">
                {showGorgiasInput && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth={true}
                      error={gorgiasSubdomainError !== ''}
                      margin="normal"
                      helperText={gorgiasSubdomainError}
                      label={t(`Enter Gorgias Subdomain`)}
                      onChange={handleGorgiasAccountChange}
                      type="text"
                      value={gorgiasSubdomain}
                      variant="outlined"
                    />
                  </Grid>
                )}

                {showGorgiasInput && (
                  <Grid item>
                    <Button
                      onClick={gorgiasSignIn}
                      startIcon={
                        isLoading ? (
                          <LoopIcon
                            sx={{
                              animation: 'spin 2s linear infinite',
                              '@keyframes spin': {
                                '0%': {
                                  transform: 'rotate(360deg)'
                                },
                                '100%': {
                                  transform: 'rotate(0deg)'
                                }
                              }
                            }}
                          />
                        ) : null
                      }
                      variant="contained"
                    >
                      {t('Login with Gorgias')}
                    </Button>
                  </Grid>
                )}
                {showGorgiasInput && (
                  <Grid
                    item
                    sx={{
                      px: 2
                    }}
                  >
                    <Button href="/" size="medium" variant="outlined">
                      {t('Cancel')}
                    </Button>
                  </Grid>
                )}
                {!showGorgiasInput && (
                  <Grid item>
                    <MicrosoftSignInButton onClick={() => microsoftSignIn()}>
                      <MicrosoftBtnSVG />
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="text.primary"
                        fontWeight="bold"
                        sx={{
                          px: 1
                        }}
                      >
                        {t('Sign in with Microsoft')}
                      </Typography>
                    </MicrosoftSignInButton>
                  </Grid>
                )}
                {!showGorgiasInput && (
                  <Grid item>
                    <GoogleSignInButton onClick={() => googleSignIn()}>
                      <GoogleBtnSVG />
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="text.primary"
                        fontWeight="bold"
                        sx={{
                          px: 1
                        }}
                      >
                        {t('Sign in with Google')}
                      </Typography>
                    </GoogleSignInButton>
                  </Grid>
                )}
                {!showGorgiasInput && (
                  <Grid item>
                    <GorgiasSignInButton onClick={setGorgiasSignInMethod}>
                      <GorgiasBtnSVG />
                    </GorgiasSignInButton>
                  </Grid>
                )}

                {!showGorgiasInput && (
                  <Grid
                    container
                    sx={{
                      py: 2
                    }}
                    item
                    xs={12}
                    justifyContent="center"
                  >
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="text.primary"
                      fontWeight="bold"
                    >
                      {t('Or')}
                    </Typography>{' '}
                  </Grid>
                )}
              </Grid>
              {!showGorgiasInput && (
                <>
                  <Box textAlign="center">
                    <Typography
                      variant="h4"
                      color="text.secondary"
                      fontWeight="normal"
                      sx={{
                        mb: 3
                      }}
                    >
                      {t('Fill in the fields below to sign into your account.')}
                    </Typography>
                  </Box>

                  <JWTLogin />
                  <Box my={4}>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="text.primary"
                      fontWeight="bold"
                    >
                      {t('Don’t have an account, yet?')}
                    </Typography>{' '}
                    <Box display={{ xs: 'block', md: 'inline-block' }}>
                      <Link component={RouterLink} to="/account/register">
                        <b>Sign up here</b>
                      </Link>
                    </Box>
                  </Box>
                </>
              )}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
