import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import EmailIcon from '@mui/icons-material/Email';
import { ReactComponent as outlookIcon } from '../../../../assets/images/outlook.svg';
import { ReactComponent as gmailIcon } from '../../../../assets/images/gmail.svg';
import { ReactComponent as twitterIcon } from '../../../../assets/images/twitter.svg';
import { ReactComponent as trustPilotIcon } from '../../../../assets/images/trustpilot.svg';
import { ReactComponent as amazonIcon } from '../../../../assets/images/amazon.svg';
import { ReactComponent as googleIcon } from '../../../../assets/images/google.svg';
import { ReactComponent as ebayIcon } from '../../../../assets/images/ebay.svg';

import DashboardIcon from '@mui/icons-material/Dashboard';
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  disabled?: boolean;
  color?: string;
  items?: MenuItem[];
  name: string;
  Authorised?: boolean;
  role?: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  role?: string;
}

const menuItems: MenuItems[] = [
  // {
  //   heading: 'Dashboards',
  //   role: 'Outlook,Gmail,TrustPilot,AmazonReviews,GoogleReviews,Twitter',
  //   items: [
  //     {
  //       name: 'Dashboard',
  //       icon: DashboardIcon,
  //       link: 'dashboards/analytics',
  //       role: ''
  //     },
  //   ]},
  {
    heading: 'Email',
    role: 'Outlook,Gmail,TrustPilot,AmazonReviews,EbayReviews,GoogleReviews',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: 'dashboards/analytics',
        role: 'Outlook,Gmail'
      },
      {
        name: 'Outlook',
        icon: outlookIcon,
        link: 'applications/mailbox/inbox',
        badge: '',
        role: 'Outlook,TrustPilot,AmazonReviews,EbayReviews,GoogleReviews'
      },
      {
        name: 'Gmail',
        icon: gmailIcon,
        link: 'applications/Gmail/inbox',
        badge: '',
        disabled: false,
        role: 'Gmail,TrustPilot,AmazonReviews,EbayReviews,GoogleReviews'
      }
    ]
  },
  {
    heading: 'Reviews',
    role: 'TrustPilot,AmazonReviews,EbayReviews,GoogleReviews',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: 'dashboards/ReviewAnalytics',
        disabled: false,
        role: 'TrustPilot,AmazonReviews,EbayReviews,GoogleReviews'
      },
      {
        name: 'Trustpilot',
        icon: trustPilotIcon,
        link: 'applications/reviews/trustpilot',
        disabled: false,
        role: 'TrustPilot'
      },
      {
        name: 'Amazon',
        icon: amazonIcon,
        link: 'applications/reviews/amazon',
        disabled: false,
        role: 'AmazonReviews'
      },
      {
        name: 'Ebay',
        icon: ebayIcon,
        link: 'applications/reviews/ebay',
        disabled: false,
        role: 'EbayReviews'
      },
      {
        name: 'Google',
        icon: googleIcon,
        link: 'applications/reviews/googleReview',
        disabled: false,
        role: 'GoogleReviews'
      }
    ]
  },
  {
    heading: 'Social Media',
    role: 'Twitter',
    items: [
      {
        name: 'Twitter',
        icon: twitterIcon,
        link: 'management/users/list',
        disabled: true,
        role: 'Twitter'
      }
    ]
  },
  {
    heading: 'Management',
    role: 'Admin',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/accent-sidebar/management/users',
        role: 'Admin',
        items: [
          {
            name: 'List',
            link: 'management/users/list',
            disabled: false,
            role: 'Admin'
          },
          {
            name: 'User Profile',
            link: 'management/users/single',
            disabled: true,
            role: 'Admin'
          }
        ]
      }
    ]
  }
];

export default menuItems;
