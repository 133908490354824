import {
  Box,
  Tooltip,
  Badge,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme,
  Typography,
  Dialog,
  DialogTitle
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LogoIMG from '../../assets/images/deriskly-logo.svg';
import { FC, useEffect, useState } from 'react';
import Status500 from 'src/content/pages/Status/Status500';
import { useAuthStore } from 'src/store/authStore';
import StatusNeedApproval from 'src/content/pages/Status/NeedApproval';
import SubscriptionEnded from '../SubscriptionEnded';

export const LogoText = styled(Typography)({
  fontFamily: 'Arial',
  fontSize: 28,
  fontWeight: 'bold',
  marginLeft: '16px',
  color: '#373737',
  '@media(max-width:1150px)': {
    fontSize: 0
  }
});

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
`
);

const LogoSign = styled(Box)(
  ({ theme }) => `
        background: ${theme.general.reactFrameworkColor};
        width: 18px;
        height: 18px;
        border-radius: ${theme.general.borderRadiusSm};
        position: relative;
        transform: rotate(45deg);
        top: 3px;
        left: 17px;

        &:after, 
        &:before {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            position: absolute;
            top: -1px;
            right: -20px;
            transform: rotate(0deg);
            border-radius: ${theme.general.borderRadiusSm};
        }

        &:before {
            background: ${theme.palette.primary.main};
            right: auto;
            left: 0;
            top: 20px;
        }

        &:after {
            background: ${theme.palette.secondary.main};
        }
`
);

const LogoSignInner = styled(Box)(
  ({ theme }) => `
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 5;
        border-radius: ${theme.general.borderRadiusSm};
        background: ${theme.header.background};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));
export const JustifyLogoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  cursor: 'pointer'
});
export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}
const Logo: FC = () => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const subsExpired = useAuthStore((state) => state.subscriptionExpired);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const isPaymentPage = location.pathname.indexOf('invoices', 0) >= 0;
  const isForgotPasswordPage =
    location.pathname.indexOf('recover-password', 0) >= 0;
  const isRegisterPage = location.pathname.indexOf('register', 0) >= 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  return (
    <>
      <TooltipWrapper title={t('Deriskly')} arrow>
        <LogoWrapper to="/dashboards/analytics">
          <LogoSignWrapper>
            <img src={LogoIMG} alt="Logo" />
          </LogoSignWrapper>
          <Badge
            sx={{
              '.MuiBadge-badge': {
                fontSize: theme.typography.pxToRem(11),
                right: -2,
                top: 8
              }
            }}
            overlap="circular"
            color="success"
            badgeContent="1.0"
          >
            <LogoText>Deriskly</LogoText>
          </Badge>
        </LogoWrapper>
      </TooltipWrapper>
      
      {subsExpired &&
        !isPaymentPage &&
        !isForgotPasswordPage &&
        !isRegisterPage && (
          <Dialog onClose={handleClose} open={true}>
            <DialogTitle>Subscription needed</DialogTitle>
            <SubscriptionEnded />
          </Dialog>
        )}
    </>
  );
};

export default Logo;
