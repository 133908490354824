import { Box, Button, Typography } from '@mui/material';
import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';

function HeaderButtons() {
  return (
    <Box>
      {/* <HeaderNotifications /> */}
      {/* <LanguageSwitcher /> */}
      <Button href="/help/videoHelp">{'Help'}</Button>
    </Box>
  );
}

export default HeaderButtons;
