import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Users = Loader(lazy(() => import('src/content/management/Users')));
const Invoices = Loader(lazy(() => import('src/content/management/Invoices')));

const SingleUser = Loader(
  lazy(() => import('src/content/management/Users/single'))
);
const Workspaces = Loader(
  lazy(() => import('src/content/management/Users/workspaces'))
);
const Configurations = Loader(
  lazy(() => import('src/content/management/Users/configurations'))
);
const managementRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />
  },
  {
    path: 'invoices',
    children: [
      {
        path: '',
        element: <Invoices />
      }
    ]
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        element: <Navigate to="list" replace />
      },
      {
        path: 'list',
        element: <Users />
      },
      {
        path: 'single',
        children: [
          {
            path: '',
            element: <Navigate to="1" replace />
          },
          {
            path: ':userId',
            element: <SingleUser />
          }
        ]
      },
      {
        path: 'workspaces',
        element: <Workspaces />
      },
      {
        path: 'configurations',
        element: <Configurations />
      }
    ]
  }
];

export default managementRoutes;
